import loadable from "@loadable/component";

const Accordion = loadable(() => import("./accordion.js"));
const Gallery = loadable(() => import("./gallery.js"));
const LockedGroupSlideshow = loadable(() => import("./slideshow.js"));
const Image = loadable(() => import("./image.js"));
const Form = loadable(() => import("./form.js"));
const LockedGroupTabs = loadable(() => import("./tabs.js"));
const Query = loadable(() => import("./query.js"));
const Blockpost = loadable(() => import("./post.js"));
const Definitions = loadable(() => import("./definitions.js"));
const Search = loadable(() => import("../layout/header/search.js"));
const Map = loadable(() => import("./map.js"));

export default {
  Accordion,
  Gallery,
  LockedGroupTabs,
  Image,
  Form,
  Query,
  Blockpost,
  Definitions,
  Search,
  LockedGroupSlideshow,
  Map
};
