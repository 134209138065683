import React from "react";
import { attributesToProps } from "html-react-parser";
import customComponents from "./index.js";

const CustomBlock = (props) => {
  const {
    domNode
  } = props;
  let conf = {};
  let component = domNode.attribs.class
    .split(" ")
    .find(e => e !== "custom-component");
  component = component.split("gco-")[1] || component.split("core-")[1] || component.split("-").slice(1).join("");
  if ((component === "image")
    && (domNode.parent)
    && (domNode.parent.name === "blockquote")) {
    conf.size = "thumb";
  }
  component = component.split("-").map(e => e[0].toUpperCase()+e.slice(1)).join("");
  const style = (attributesToProps(domNode.attribs).style || {});
  const Node = customComponents[component];
  const propsCopy = {...props};
  delete propsCopy.content;
  try {
    return <Node
      style={ style }
      { ...JSON.parse(domNode.children[0].data || "{}") }
      {...conf}
      {...propsCopy}
    >{ domNode.children }</Node>;
  } catch(e) {
    console.error(e, domNode); // eslint-disable-line
    console.log(domNode.children); // eslint-disable-line
  }
  return (<></>);
};

export default CustomBlock;
